import React, { useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

import { localizedString } from '@languages';
import { DocumentListScreen } from '@lib/pages/v2/DocumentListScreen';
import { ManualEntryFormScreen } from '@lib/pages/v2/ManualEntryFormScreen';
import { Message } from '@FLOW_V2_FLOW/components';
import { DOCUMENTS } from '@js/lib/constants/documents';

const title = localizedString('selectYourDocument');
const initialDocumentList = [
  {
    type: DOCUMENTS.DRIVER_LICENCE.documentType,
    cardType: DOCUMENTS.DRIVER_LICENCE.cardType,
    title: localizedString('ID_TYPE_AU_LICENCE'),
    icon: 'icon_card.svg',
    checked: false
  },
  {
    type: DOCUMENTS.PASSPORT.documentType,
    cardType: DOCUMENTS.PASSPORT.cardType,
    title: localizedString('ID_TYPE_AU_PASSPORT'),
    icon: 'icon_passport.svg',
    checked: false
  },
  {
    type: DOCUMENTS.PASSPORT.documentType,
    cardType: DOCUMENTS.PASSPORT.cardType,
    title: `${localizedString('COUNTRY_OTHER')} ${localizedString('ID_TYPE_OTHER_PASSPORT')}`,
    icon: 'icon_passport.svg',
    includeCountry: true,
    checked: false
  }
];

const MANUAL_ENTRY_STEPS = {
  DOCUMENT_SELECTION: 'DOCUMENT_SELECTION',
  FILL_FORM: 'FILL_FORM',
  RETRY_DATA_CHECK: 'RETRY_DATA_CHECK'
};

export const ManualEntryScreen = ({ onCloseManualEntryScreen, onComplete, flowType }) => {
  const [initialData, setInitialData] = useState({});
  const [currentStep, setCurrentStep] = useState(MANUAL_ENTRY_STEPS.DOCUMENT_SELECTION);
  const [documentList, setDocumentList] = useState(initialDocumentList);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const { FLOW_V2_HIDE_IMAGES_IN_DOC_LIST } = process.env;

  const confirmDataButtons = [
    {
      label: localizedString('dataCheck.FLOW_V2_DATA_CHECK_REVIEW_DATA_BUTTON_TEXT'),
      type: 'submit',
      onClick: () => setCurrentStep(MANUAL_ENTRY_STEPS.FILL_FORM)
    }
  ];

  const handleClickDocumentItem = (item) => {
    setDocumentList(
      documentList.map((document) => {
        const isSelected = document === item;
        if (isSelected) {
          setSelectedDocument(document);
        }
        return {
          ...document,
          checked: isSelected
        };
      })
    );
  };

  const handleDataCheckReady = ({ fields, hasFailed = false }) => {
    if (hasFailed) {
      setInitialData(fields);
      setCurrentStep(MANUAL_ENTRY_STEPS.RETRY_DATA_CHECK);
    } else {
      onComplete();
    }
  };

  const proceedToFillForm = () => {
    setCurrentStep(MANUAL_ENTRY_STEPS.FILL_FORM);
  };

  const handleCloseManualEntryFormScreen = ({ exitCompletely = false } = {}) => {
    if (exitCompletely) {
      onCloseManualEntryScreen();
    } else {
      setCurrentStep(MANUAL_ENTRY_STEPS.DOCUMENT_SELECTION);
    }
  };

  const footerButtons = [
    {
      label: localizedString('back'),
      variant: 'transparent',
      onClick: onCloseManualEntryScreen
    },
    {
      label: localizedString('enterMyDetails'),
      type: 'submit',
      disabled: !selectedDocument,
      onClick: proceedToFillForm
    }
  ];

  if (currentStep === MANUAL_ENTRY_STEPS.DOCUMENT_SELECTION) {
    return (
      <DocumentListScreen
        title={title}
        documentList={documentList}
        onClickDocumentItem={handleClickDocumentItem}
        footerButtons={footerButtons}
        hideDocImages={FLOW_V2_HIDE_IMAGES_IN_DOC_LIST}
      />
    );
  }

  if (currentStep === MANUAL_ENTRY_STEPS.FILL_FORM) {
    return (
      <ManualEntryFormScreen
        initialData={initialData}
        onDataCheckReady={handleDataCheckReady}
        documentCardType={selectedDocument ? selectedDocument.cardType : null}
        includeCountry={selectedDocument ? selectedDocument.includeCountry : false}
        onCloseManualEntryFormScreen={handleCloseManualEntryFormScreen}
        flowType={flowType}
      />
    );
  }

  if (currentStep === MANUAL_ENTRY_STEPS.RETRY_DATA_CHECK) {
    return (
      <div>
        <Message
          buttons={confirmDataButtons}
          title={localizedString('dataCheck.FLOW_V2_DATA_CHECK_NO_MATCH_TITLE')}
          issue
        >
          {parse(localizedString('dataCheck.FLOW_V2_DATA_CHECK_NO_MATCH_INFO_MESSAGE'))}
        </Message>
      </div>
    );
  }

  return null;
};

ManualEntryScreen.propTypes = {
  onCloseManualEntryScreen: PropTypes.func,
  onComplete: PropTypes.func,
  flowType: PropTypes.string
};
