import React, { useState, useEffect } from 'react';
import unionBy from 'lodash/unionBy';
import isEmpty from 'lodash/isEmpty';
import pipeFp from 'lodash/fp/pipe';
import mapFp from 'lodash/fp/map';
import intersectionWithFp from 'lodash/fp/intersectionWith';
import orderByFp from 'lodash/fp/orderBy';
import sortByFp from 'lodash/fp/sortBy';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { localizedString } from '@languages';
import APIs from '@services/APIs';
import { format } from 'react-string-format';
import parse from 'html-react-parser';
import VoiFlowV2Action from '@store/actions/voiFlowV2';
import { isShownPrivacy } from '@lib/Utils';
import { DocumentListScreen } from '@lib/pages/v2/DocumentListScreen';
import {
  availableDocumentListPart1,
  availableDocumentListPart2,
  oneDocConditionDocumentList,
  oneDocConditionAdditionalDocumentList
} from './documentList';
import { getDocumentList } from './getDocumentList';

const {
  VOI_FLOW_V2_SELECT_ID_TITLE_FORMAT = 'Select your {0} ID',
  VOI_FLOW_V2_BEFORE_SELECT_DESC = 'You will need to use multiple identity documents to pass VOI.',
  VOI_FLOW_V2_WELL_DONE_TITLE = 'Well Done!',
  VOI_FLOW_V2_WELL_DONE_DESCRIPTION = 'Continue to the next step where you will take a photo of your selected documents.',
  FLOW_V2_HIDE_IMAGES_IN_DOC_LIST,
  VOI_FLOW_V2_ONLY_INCLUDE_IN_THIRD_LIST = [],
  VOI_FLOW_V2_FILTER_DOCS = [],
  ENABLE_ONE_DOC_CONDITION = false
} = process.env;

const docTypeNamesListPart2 = availableDocumentListPart2.map((doc) => doc.type);

const nthLabel = {
  0: 'first',
  1: 'second',
  2: 'third',
  3: 'fourth',
  4: 'fifth',
  5: 'sixth',
  6: 'seventh',
  7: 'eighth',
  8: 'ninth',
  9: 'tenth'
};

function DocumentSelection({
  initialSelectedDocumentList = [],
  requiredDocumentConfig,
  enableOneDocCondition,
  onGoBack,
  onNextStep,
  currentDocument,
  clearCardDetails
}) {
  let documentListPart1 = availableDocumentListPart1;

  if (enableOneDocCondition) {
    documentListPart1 =
      currentDocument.index < 1
        ? oneDocConditionDocumentList
        : oneDocConditionAdditionalDocumentList;
  }

  const docTypeNamesListPart1 = documentListPart1.map((doc) => doc.type);
  const availableDocuments = unionBy(
    initialSelectedDocumentList,
    [...documentListPart1, ...availableDocumentListPart2],
    'type'
  );

  const [selectedDocTypes, setSelectedDocTypes] = useState([]);

  const [currentDocumentList, setCurrentDocumentList] = useState(() => getCurrentDocumentList());

  const wellDone =
    selectedDocTypes.length && selectedDocTypes.length === currentDocumentList.length;

  useEffect(() => {
    if (isEmpty(initialSelectedDocumentList)) {
      clearCardDetails();
      APIs.status('selectId', { resetTables: ENABLE_ONE_DOC_CONDITION ? 'no' : 'yes' });
    }
  }, []);

  useEffect(() => {
    setCurrentDocumentList(getCurrentDocumentList());
  }, [selectedDocTypes]);

  useEffect(() => {
    if (wellDone && enableOneDocCondition) {
      onNextStep([currentDocumentList[0]]);
    }
  }, [wellDone]);

  const { backurl } = document.body.dataset;
  const footerButtons = [];
  if (backurl || isShownPrivacy('VOI_FLOW_V2')) {
    footerButtons.push({
      label: localizedString('back'),
      variant: 'transparent',
      onClick: handleGoBack,
      dataTestId: 'docs-back'
    });
  }
  footerButtons.push({
    label: localizedString('continue'),
    type: 'submit',
    disabled: !wellDone,
    onClick: handleNextStep,
    dataTestId: 'docs-continue'
  });

  const selectedDocumentCount = currentDocumentList.filter((doc) => doc.checked).length;
  const progress = wellDone ? 100 : (selectedDocumentCount * 100) / 3;

  let title = wellDone
    ? VOI_FLOW_V2_WELL_DONE_TITLE
    : format(VOI_FLOW_V2_SELECT_ID_TITLE_FORMAT, nthLabel[selectedDocumentCount]);
  let description = wellDone
    ? VOI_FLOW_V2_WELL_DONE_DESCRIPTION
    : parse(VOI_FLOW_V2_BEFORE_SELECT_DESC);

  if (enableOneDocCondition) {
    const { index } = currentDocument;
    if (index < 1) {
      title = 'Select your I.D';
      description = 'Select the original I.D document you have available.';
    } else {
      title = 'Select an additional I.D';
      description = 'You will need to select an additional I.D for your identity check.';
    }
  }

  function getCurrentDocumentList() {
    const filterDocs = {};
    if (!isEmpty(VOI_FLOW_V2_ONLY_INCLUDE_IN_THIRD_LIST)) {
      filterDocs.appearOnlyAsThirdDoc = VOI_FLOW_V2_ONLY_INCLUDE_IN_THIRD_LIST;
    }
    if (!isEmpty(VOI_FLOW_V2_FILTER_DOCS)) {
      filterDocs.filters = VOI_FLOW_V2_FILTER_DOCS;
    }

    const documentTypesToShow = getDocumentList({
      alreadySelectedDocs: selectedDocTypes,
      documentListPart1: docTypeNamesListPart1,
      documentListPart2: docTypeNamesListPart2,
      conditionsToBeConsideredAsComplete: requiredDocumentConfig,
      filterDocs
    });

    return pipeFp(
      (docs) => intersectionWithFp((doc, type) => doc.type === type)(docs)(documentTypesToShow),
      sortByFp((doc) => selectedDocTypes.indexOf(doc.type)),
      mapFp((doc) => {
        if (selectedDocTypes.includes(doc.type)) {
          return {
            ...doc,
            checked: true
          };
        }

        return doc;
      }),
      orderByFp(['checked'], ['desc'])
    )(availableDocuments);
  }

  function onCardClick(item) {
    if (item.checked) {
      // disable deselect document for now
      // setSelectedDocTypes(selectedDocTypes.filter((docType) => docType !== item.type));
    } else if (!selectedDocTypes.includes(item.type)) {
      setSelectedDocTypes([...selectedDocTypes, item.type]);
    }
  }

  function handleGoBack(e) {
    if (e) {
      e.preventDefault();
    }

    if (backurl && !isShownPrivacy('VOI_FLOW_V2')) {
      window.location.href = backurl;
    } else {
      onGoBack();
    }
  }

  function handleNextStep(e) {
    if (e) {
      e.preventDefault();
    }

    onNextStep(currentDocumentList);
  }

  return (
    <DocumentListScreen
      title={title}
      description={description}
      documentList={currentDocumentList}
      onClickDocumentItem={(item, index) => onCardClick(item, index)}
      footerButtons={footerButtons}
      hideDocImages={FLOW_V2_HIDE_IMAGES_IN_DOC_LIST}
      progressBarPercentage={progress}
    />
  );
}

DocumentSelection.propTypes = {
  initialSelectedDocumentList: PropTypes.array,
  requiredDocumentConfig: PropTypes.array,
  onGoBack: PropTypes.func,
  onNextStep: PropTypes.func,
  currentDocument: PropTypes.any,
  clearCardDetails: PropTypes.func,
  enableOneDocCondition: PropTypes.bool
};

export default connect(null, mapDispatchToProps)(DocumentSelection);

function mapDispatchToProps(dispatch) {
  return {
    clearCardDetails: () => dispatch(VoiFlowV2Action.clearCardDetails())
  };
}
