import moment from 'moment';
import { reverseDateFormatSF } from '@lib/Utils';
import { toDocumentType } from '@lib/constants/documents';

export const isEligibleForPassportExpiredLogic = (appConfig, cardType, countryCode) => {
  if (appConfig && appConfig.acceptExpiredPassportUpto) {
    const eligibleCountries = Object.keys(appConfig.acceptExpiredPassportUpto);
    return cardType.match(/PASSPORT/i) && eligibleCountries.includes(countryCode);
  }
  return false;
};

export const isPassportExpiredBy2Years = (expiryDate, { appConfig, cardType, countryCode }) => {
  if (isEligibleForPassportExpiredLogic(appConfig, cardType, countryCode)) {
    const limitInYears = appConfig.acceptExpiredPassportUpto[countryCode];
    const enteredExpiry = moment(expiryDate, 'DD-MM-YYYY');
    const expiryDateLimit = moment(new Date()).subtract(limitInYears, 'years');
    return enteredExpiry.isBefore(expiryDateLimit, 'day');
  }

  return false;
};

export const isDocumentExpired = (expiryDate) => {
  const now = new Date().getTime();
  return now > reverseDateFormatSF(expiryDate, true).getTime();
};

export const isExpiredIdAllowed = (flowType) => {
  const { FLOW_V2_ALLOW_EXPIRED_ID = false, VOI_FLOW_V2_ALLOW_EXPIRED_ID = false } = process.env;
  if (flowType === 'FLOW_V2') {
    return FLOW_V2_ALLOW_EXPIRED_ID;
  }
  if (flowType === 'VOI_FLOW_V2') {
    return VOI_FLOW_V2_ALLOW_EXPIRED_ID;
  }
  return false;
};

export const isEmptyExpiryDateAllowed = ({ flowType, cardType }) => {
  const {
    FLOW_V2_ALLOWED_TYPES_WITH_EMPTY_EXPIRY = [],
    VOI_FLOW_V2_ALLOWED_TYPES_WITH_EMPTY_EXPIRY = []
  } = process.env;
  const docType = toDocumentType(cardType) || cardType;
  if (flowType === 'FLOW_V2') {
    return FLOW_V2_ALLOWED_TYPES_WITH_EMPTY_EXPIRY.includes(docType);
  }
  if (flowType === 'VOI_FLOW_V2') {
    return VOI_FLOW_V2_ALLOWED_TYPES_WITH_EMPTY_EXPIRY.includes(docType);
  }
  return false;
};
