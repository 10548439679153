import React from 'react';
import PropTypes from 'prop-types';
import { Validation } from 'calidation';
import classNames from 'classnames';
import { Checkbox, DatePicker, Input } from '@lib/components/v2/Form';
import { AddressFinder } from '@lib/components/v2/AddressFinder';
import { getFields } from '@lib/utils/v2/getFields';
import classes from './VerifyDetails.style.module.scss';
import { getValidations } from './verifyDetailsValidations';

const { ENABLE_ONE_DOC_CONDITION = false } = process.env;

const VerifyDetails = ({
  pageTitle = 'Are your ID details correct?',
  idType,
  idTypeTitle,
  cardType,
  flowType,
  country = 'AU',
  countryOfIssue,
  onChange,
  address,
  errors,
  checkConfirm,
  addressData,
  useIdNumber,
  isSubmiting,
  ...restProps
}) => {
  const {
    VOI_FLOW_V2_VERIFY_DETAILS_DESC = '',
    VOI_FLOW_V2_VERIFY_DETAILS_DESC_AU_DRIVER_LICENCE = null,
    VOI_FLOW_V2_VERIFY_DETAILS_CONSENT = 'I consent to my information being checked with the document issuer or official record holder.',
    FLOW_V2_DATEPICKER_FORMAT
  } = process.env;

  let NEW_VOI_FLOW_V2_LOADING_DETAILS_HEADING = VOI_FLOW_V2_VERIFY_DETAILS_DESC.replace(
    '{idType}',
    idTypeTitle
  );

  if (
    VOI_FLOW_V2_VERIFY_DETAILS_DESC_AU_DRIVER_LICENCE &&
    country === 'AU' &&
    idType.match(/LICENCE/i)
  ) {
    NEW_VOI_FLOW_V2_LOADING_DETAILS_HEADING = VOI_FLOW_V2_VERIFY_DETAILS_DESC_AU_DRIVER_LICENCE;
  }

  const fields = getFields({ idType, countryOfIssue, addressData, useIdNumber, values: restProps });
  const formValidations = getValidations({
    idType,
    countryOfIssue,
    values: restProps,
    flowType,
    cardType
  });

  /**
   * Generate form fields.
   */
  const generateFields = fields.map((field, i) => {
    const { id, label, date, value, addressFinder = false } = field;
    const labelDataTestId = `details-field-${i}-lbl`;
    const dataTestId = `details-field-${i}`;
    // if its date
    if (date) {
      return (
        <div key={id} className={classNames('', classes.userDetailRow)}>
          <span data-testid={labelDataTestId}>{field.label}</span>
          <Validation config={formValidations[id]} initialValues={{ [id]: value }}>
            {({ errors: formErrors, setField }) => {
              return (
                <DatePicker
                  key={id}
                  className={classNames(classes.input)}
                  hasError={formErrors[id]}
                  onChange={(value) => {
                    onChange(id, value);
                    setField({ [id]: value });
                  }}
                  id={id}
                  label={label}
                  value={restProps[id]}
                  dataTestId={`${dataTestId}-datePicker`}
                  displayedDateFormat={FLOW_V2_DATEPICKER_FORMAT}
                  disabled={isSubmiting}
                />
              );
            }}
          </Validation>
        </div>
      );
    }
    if (addressFinder) {
      let countryCodeIso2ToVerify;
      if (value && value.manual) {
        countryCodeIso2ToVerify = value.selectedManualCountryCode
          ? value.selectedManualCountryCode
          : 'AU';
      } else {
        countryCodeIso2ToVerify = country;
      }

      return (
        <div key={id} className={classNames('', classes.userDetailRow)}>
          <span data-testid={labelDataTestId} className={classes['address-label-span']}>
            {field.label}
          </span>
          <AddressFinder
            initialAddress={address}
            initialAddressData={addressData}
            countryCodeIso2ToVerify={countryCodeIso2ToVerify}
            onChange={(value) => onChange(id, value)}
            dataTestId={`${dataTestId}-search`}
            disabled={isSubmiting}
          />
        </div>
      );
    }
    return (
      <div key={id} className={classNames('', classes.userDetailRow)}>
        <span data-testid={labelDataTestId}>{field.label}</span>
        <Validation config={formValidations[id]} initialValues={{ [id]: value }}>
          {({ errors: formErrors, setField }) => {
            return (
              <Input
                key={id}
                placeholder={field.label}
                paddingLeft30
                className={classNames(classes.input)}
                hasError={formErrors[id]}
                onChange={(value) => {
                  onChange(id, value);
                  setField({ [id]: value });
                }}
                {...field}
                dataTestId={`${dataTestId}-txt`}
                disabled={isSubmiting}
              />
            );
          }}
        </Validation>
      </div>
    );
  });

  let containerClassName = classes.container;
  if (ENABLE_ONE_DOC_CONDITION) {
    containerClassName = classes['container-one-doc'];
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.heading} data-testid="details-heading">
        {pageTitle}
      </div>
      <div className={classes.description}>{NEW_VOI_FLOW_V2_LOADING_DETAILS_HEADING}</div>
      <div className={containerClassName}>
        {generateFields}
        <Checkbox
          className={classNames(classes['check-confirm'])}
          id="confirm"
          value={checkConfirm}
          onChange={(value) => onChange('checkConfirm', value)}
          label={VOI_FLOW_V2_VERIFY_DETAILS_CONSENT}
          dataTestId="details-checkbox"
          labelDataTestId="details-consentTxt"
          disabled={isSubmiting}
        />
      </div>
    </div>
  );
};

VerifyDetails.propTypes = {
  idType: PropTypes.string,
  cardType: PropTypes.string,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  /* Form data */
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  dateOfBirth: PropTypes.string,
  expiryDate: PropTypes.string,
  idNumber: PropTypes.string,
  licenceNumber: PropTypes.string,
  licenceVersion: PropTypes.string,
  passportNumber: PropTypes.string,
  /* Confirmation */
  checkConfirm: PropTypes.bool,
  checklicenceNumber: PropTypes.bool,
  checklicenceVersion: PropTypes.bool,
  checkpassportNumber: PropTypes.bool,

  pageTitle: PropTypes.string,
  idTypeTitle: PropTypes.string,
  flowType: PropTypes.string,
  country: PropTypes.string,
  countryOfIssue: PropTypes.string,
  address: PropTypes.any,
  addressData: PropTypes.any,
  useIdNumber: PropTypes.bool,
  isSubmiting: PropTypes.bool
};

VerifyDetails.defaultProps = {
  idType: 'NZL_DRIVERLICENCE',
  errors: {},
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',
  expiryDate: '',
  passportNumber: '',
  idNumber: '',
  licenceNumber: '',
  licenceVersion: '',
  onChange: () => {}
};

export default VerifyDetails;
