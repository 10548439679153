/* eslint-disable import/no-unresolved */
import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';
import sortBy from 'lodash/sortBy';

import enAU from './compiled/en-au.json';
import enUK from './compiled/en-uk.json';
import enUS from './compiled/en-us.json';

import ar from './compiled/ar.json';
import es from './compiled/es.json';
import hi from './compiled/hi.json';
import id from './compiled/id.json';
import jp from './compiled/jp.json';
import ms from './compiled/ms.json';
import pt from './compiled/pt.json';
import tl from './compiled/tl.json';
import tr from './compiled/tr.json';
import zh from './compiled/zh.json';
/* eslint-enable import/no-unresolved */

const { DEFAULT_LANGUAGE = 'en' } = process.env;

const ENGLISH_VARIANTS = [
  {
    name: 'en',
    title: 'English (AU)',
    dict: enAU,
    default: true,
    countryCode: 'AU'
  },
  {
    name: 'en-uk',
    title: 'English (UK)',
    dict: enUK,
    countryCode: 'GB'
  },
  {
    name: 'en-us',
    title: 'English (US)',
    dict: enUS,
    default: true,
    countryCode: 'US'
  }
];

const OTHER_LANGUAGES = [
  {
    name: 'zh',
    title: 'Chinese (中文)',
    dict: zh,
    countryCode: 'CN'
  },
  {
    name: 'tr',
    title: 'Turkish (Türkce)',
    dict: tr,
    countryCode: 'TR'
  },
  {
    name: 'ar',
    title: 'Arabic (العربية)',
    dict: ar,
    countryCode: 'SA'
  },
  {
    name: 'hi',
    title: 'Hindi (हिन्दी)',
    dict: hi,
    countryCode: 'IN'
  },
  {
    name: 'es',
    title: 'Spanish (Español)',
    dict: es,
    countryCode: 'ES'
  },
  {
    name: 'jp',
    title: 'Japanese (日本語)',
    dict: jp,
    countryCode: 'JP'
  },
  {
    name: 'ms',
    title: 'Malay (Melayu)',
    dict: ms,
    countryCode: 'MY'
  },
  {
    name: 'id',
    title: 'Indonesian (bahasa Indonesia)',
    dict: id,
    countryCode: 'ID'
  },
  {
    name: 'tl',
    title: 'Filipino (Tagalog)',
    dict: tl,
    countryCode: 'PH'
  },
  {
    name: 'pt',
    title: 'Portuguese (Português)',
    dict: pt,
    countryCode: 'BR'
  }
];

export const LANGUAGES = [...ENGLISH_VARIANTS, ...sortBy(OTHER_LANGUAGES, ['title'])];

const getLanguageItemPerName = (name = '') => {
  return LANGUAGES.find((langItem) => langItem.name.toUpperCase() === name.toUpperCase());
};

export const getDefaultLanguage = () => {
  let defaultLang = getLanguageItemPerName(DEFAULT_LANGUAGE);
  if (!defaultLang) {
    [defaultLang] = LANGUAGES;
  }

  return defaultLang;
};

export const setLanguage = (language) => {
  document.body.dataset.language = language;
};

export const getLanguage = () => {
  let { language } = document.body.dataset;
  const languageItem = LANGUAGES.find((item) => item.name === language);

  if (!language || language.trim().length === 0 || !languageItem) {
    if (language) {
      console.warn('Language', language, 'not found yet. Switching to default language-English');
    }
    language = getDefaultLanguage().name;
  }

  return language;
};

export const localizedString = (name, ...values) => {
  const languageName = getLanguage();
  const languageDict = LANGUAGES.find((item) => item.name === languageName).dict;

  let result = languageDict?.[name];

  if (isNil(result)) {
    // console.warn('Cannot find localized string for key:', name);
    // alert("Cannot find localized string for key: " + name)

    const defaultLang = getDefaultLanguage();
    result = isNil(defaultLang?.dict?.[name]) ? name : defaultLang?.dict?.[name];
  }

  if (values && values.length) {
    return values.reduce((finalString, value) => {
      return finalString.replace('{}', value);
    }, result);
  }

  return result;
};

export const isLocalizedStringDefined = (name) => {
  const result = localizedString(name);
  return result && result !== name;
};

export const getLocalizedIdvcContent = (setting) => {
  if (!setting) {
    return null;
  }

  if (isArray(setting)) {
    const currentLanguage = getLanguage();
    const localizedSetting = setting.find((item) => item.language === currentLanguage);

    if (localizedSetting) {
      return localizedSetting;
    }

    if (currentLanguage === DEFAULT_LANGUAGE) {
      // For old settings that did not have language field assumption is that it was english.
      return setting.find((item) => !item.language);
    }
  }

  return setting;
};
